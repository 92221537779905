.detail-flex {
    display: flex;
    justify-content: space-between;
    width: 800px;
    margin: auto;
    margin-top: 30px;
    margin-bottom: 30px;
}
.detail-page-img {
    width: 200px;
    height: 340px;
    object-fit: cover;
}
.movie-details-container {
    width: 500px;
}
h3.similar-movies-heading {
    margin-left: 70px;
    font-size: 25px;
    position: relative;
}
h3.similar-movies-heading::before {
    content: '';
    position: absolute;
    top: 0px;
    left: -20px;
    width: 10px;
    height: 100%;
    background-color: aqua;
}
.recommend-slider {
    margin: 0px 50px !important;
}
.top-25-imdb {
    margin-bottom: 50px;
}
.search-results-container {
    width: calc(100vw - 100px);
    max-width: 800px;
    margin: auto;
    padding-top: 30px;
}
.each-search-item {
    width: 100%;
    min-height: 110px;
    background-color: rgb(20, 20, 20);
    margin-bottom: 20px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    color: white;
    text-decoration: none;
    transition: all 0.3s;
}
.each-search-item:hover {
    background-color: rgb(41, 41, 41);
    box-shadow: 3px 3px 5px black;
}
.each-search-item img {
    margin-left: 30px;
    margin-right: 50px;
}

@media screen and  (min-width: 1600px) {
    .homepage-container {
        margin-top: 60px;
    }
}
@media screen and (max-width: 900px) {
    .detail-flex {
        flex-direction: column;
        align-items: center;
        text-align: center;
        width: calc(100vw - 60px);
        margin: auto;
        margin-top: 30px;
    }
    .movie-details-container {
        width: 100%;
    }
    .recommend-slider {
        margin: 0px 30px !important;
    }
    h3.similar-movies-heading  {
        margin-left: 50px;
    }
}
@media screen and (max-width: 700px) {
    #root {
        padding-top: 120px;
    }
}
@media screen and (max-width: 450px) {
    .search-results-container {
        width: calc(100vw - 40px);
    }
}