.loader-container {
    position: fixed;
    top: 0px;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.507);
    z-index: 10 !important;
}
.bubble {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: black;
    margin: 0px 7px;
    position: relative;
    animation: bubblefly 0.9s infinite;
}
.bubble:first-child {
    background: rgb(255,150,12);
    animation-delay: 0.1s;
    background: linear-gradient(0deg, rgba(255,150,12,1) 0%, rgba(255,30,30,1) 100%);
}
.bubble:nth-child(2) {
    animation-delay: 0.2s;
    background: linear-gradient(0deg, rgb(255, 251, 12) 0%, rgba(84,255,30,1) 100%);
}
.bubble:nth-child(3) {
    background: rgb(12,255,122);
    animation-delay: 0.3s;
    background: linear-gradient(0deg, rgba(12,255,122,1) 0%, rgba(0,211,255,1) 100%);
}
.bubble:nth-child(4) {
    background: rgb(12,90,255);
    animation-delay: 0.4s;
    background: linear-gradient(0deg, rgba(12,90,255,1) 0%, rgba(128,0,255,1) 100%);
}
.bubble:nth-child(5) {
    background: rgb(255,12,254);
    animation-delay: 0.5s;
    background: linear-gradient(0deg, rgba(255,12,254,1) 0%, rgba(0,172,255,1) 100%);
}
.bubble:last-child {
    background: rgb(255,12,12);
    animation-delay: 0.6s;
    background: linear-gradient(0deg, rgba(255,12,12,1) 0%, rgba(255,171,0,1) 100%);
}
@keyframes bubblefly {
    0% {top: 0px;}
    30% {top: -40px;}
    60% {top: 0px;}
}