.card-container {
    position: relative;
    width: 220px;
    height: 300px;
    color: white;
    padding: 10px 15px;
    padding-bottom: 15px;
    text-shadow: 0px 0px 3px black;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    cursor: pointer;

    /* forced for testing */
    /* margin-bottom: 50px; */
}
.card-container::before {
    content: '';
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: 100%;
    height: 150px;
    background: rgb(0,0,0);
    background: linear-gradient(0deg, rgb(0, 0, 0) 0%, rgba(255,255,255,0) 100%);
    z-index: -1;
    transition: all 0.3s;
}
.card-container:hover::before {
    height: 230px;
}
.card-container h3,
.card-container p {
    z-index: 2 !important;
}
.card-container h3 {
    /* margin-top: 195px; */
    margin-bottom: 10px !important;
    font-size: 17px;
}
.card-container p {
    font-size: 13px;
    margin: 0px;
    color: rgb(212, 212, 212);
}
.small-poster-img {
    position: absolute;
    top: 0;
    left: 0;
    /* width: 250px;
    height: 320px; */
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -2;
}

@media screen and (max-width: 557px) {
    .card-container {
        width: 180px;
        height: 250px;
    }
}